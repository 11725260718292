import { createRouter, createWebHistory, type RouteRecordRaw } from "vue-router"

const routes: RouteRecordRaw[] = [
  {
    path: "",
    component: () => import("~/layouts/default.vue"),
    children: [
      {
        path: "",
        component: () => import("~/pages/index.vue"),
        meta: { title: "Records" },
        sensitive: true,
      },
      { path: "/records/:id", component: () => import("~/pages/records/_id/index.vue") },
      {
        path: "/storage/:id",
        component: () => import("~/pages/storage/_id.vue"),
        meta: { title: "Storage" },
      },
      {
        name: "compare",
        path: "/compare",
        component: () => import("~/pages/compare/_base_file_id/with/_revised_file_id.vue"),
        meta: { title: "Compare" },
      },
      {
        path: "/compare/:base_file_id/with/:revised_file_id",
        component: () => import("~/pages/compare/_base_file_id/with/_revised_file_id.vue"),
        meta: { title: "Compare" },
      },
      {
        path: "/getting-started",
        component: () => import("~/pages/getting-started/index.vue"),
      },
      {
        path: "/getting-started/add-file-storage",
        component: () => import("~/pages/getting-started/add-file-storage.vue"),
      },
      {
        path: "/getting-started/clean-up-extracted-party",
        component: () => import("~/pages/getting-started/clean-up-extracted-party.vue"),
      },
      {
        path: "/getting-started/connect-email",
        component: () => import("~/pages/getting-started/connect-email.vue"),
      },
      {
        name: "clause-search",
        path: "/clause-search",
        component: () => import("~/pages/clause-search.vue"),
        meta: { title: "Clause Search" },
      },
    ],
  },
  {
    component: () => import("~/layouts/office-addin.vue"),
    path: "/office-addin",
    children: [
      {
        path: "/office-addin/callback",
        component: () => import("~/pages/office-addin/callback.vue"),
      },
      {
        path: "clause-search",
        component: () => import("~/pages/office-addin/clause-search.vue"),
      },
      {
        path: "compare",
        component: () => import("~/pages/office-addin/compare.vue"),
      },
      {
        path: "reference-document-upload",
        component: () => import("~/pages/office-addin/reference-document-upload.vue"),
      },
      {
        name: "office-addin-welcome",
        path: "welcome",
        component: () => import("~/pages/office-addin/welcome.vue"),
      },
    ],
  },
  {
    component: () => import("~/layouts/loggedout.vue"),
    path: "",
    children: [
      {
        path: "/messages/:message_id/compare_with_previous",
        component: () => import("~/pages/messages/_message_id/compare_with_previous.vue"),
      },
      {
        path: "/messages/:message_id/open_record",
        component: () => import("~/pages/messages/_message_id/open_record.vue"),
      },
      {
        name: "authenticate",
        path: "/authenticate",
        component: () => import("~/pages/authenticate.vue"),
        meta: { title: "Logging in..." },
      },
      {
        name: "authorize",
        path: "/authorize",
        component: () => import("~/pages/authorize.vue"),
        meta: { title: "Applying..." },
      },
      { name: "login", path: "/login", component: () => import("~/pages/login.vue") },
      { name: "logout", path: "/logout", component: () => import("~/pages/logout.vue") },
      {
        name: "office-addin-login",
        path: "/office-addin/login",
        component: () => import("~/pages/office-addin/login.vue"),
      },
      {
        name: "office-addin-logout",
        path: "/office-addin/logout",
        component: () => import("~/pages/office-addin/logout.vue"),
      },
      {
        path: "/modals/add-file-storage",
        component: () => import("~/pages/modals/add-file-storage.vue"),
      },
      // Matches anything that wasn't matched above, to render a 404
      { path: "/:catchAll(.*)*", name: "error", component: () => import("~/pages/error.vue") },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

export default router
