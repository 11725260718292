/* eslint-disable import-x/no-named-as-default-member -- ahoy exports only an object */
import { AnalyticsBrowser } from "@segment/analytics-next"
import { captureException, captureMessage } from "@sentry/vue"
import ahoy from "ahoy.js"
import { watch } from "vue"
import {
  USAGE_LOG_IN_DEV,
  apiUrl,
  isDev,
  isProduction,
  segmentWriteKey,
} from "~/config/environment"
import { useUserInfoStore } from "~/stores/userInfo"

// @ts-expect-error - we'll be removing Cypress soon anyway, will solve the type error then
const dontLogInDevOrTest = (!USAGE_LOG_IN_DEV && isDev) || window.Cypress

let analytics: AnalyticsBrowser | undefined

export const useSegment = (): AnalyticsBrowser | undefined => {
  if (!isProduction) return undefined

  if (!segmentWriteKey) throw new Error("Cannot init Segment without write key")

  if (analytics) return analytics

  analytics = new AnalyticsBrowser()

  analytics.load({ writeKey: segmentWriteKey }).catch(captureException)

  return analytics
}

export function setupAnalytics(): void {
  if (dontLogInDevOrTest) {
    // Disable in dev and e2e tests
    ahoy.configure({
      trackVisits: false,
    })
  } else {
    ahoy.configure({
      urlPrefix: apiUrl,
      trackVisits: false, // Segment is already tracking views properly
      eventsUrl: "/usage/events",
      // Send cookies to backend
      withCredentials: true,
    })
  }

  if (!isProduction) return

  const segment = useSegment()

  if (!segment) throw new Error("No segment, can't identify")

  const userInfoStore = useUserInfoStore()

  watch(
    () => userInfoStore.data,
    (newUserInfo, oldUserInfo) => {
      if (!oldUserInfo && newUserInfo) {
        const decodedUserId = atob(newUserInfo.id).split("-")[1]
        segment.identify(decodedUserId, {
          name: newUserInfo.name,
          email: newUserInfo.email,
        })
        segment.group(newUserInfo.tenant)
      } else if (!newUserInfo && oldUserInfo) {
        segment.reset()
      }
    },
    { immediate: true },
  )
}

// directly exported function for use with Vue 3 / Composition API
// Will export something else shortly

export function logUsage(logInfo: LogInfo): void {
  if (dontLogInDevOrTest) {
    console.log("Usage Log:", logInfo)
    return
  }

  const { event } = logInfo

  if (!event) {
    const infoJSON = JSON.stringify(logInfo)
    captureMessage(`Could not log event, there's no name -- got ${infoJSON} instead`)
    return
  }

  // use the extraData if provided; otherwise, fallback to the legacy injection
  // of the whole logInfo object
  let trackingPayload = logInfo.extraData
  if (!trackingPayload) {
    trackingPayload = { ...logInfo }
    delete trackingPayload.event
  }

  // extraMessage gets ignored if extraData is present, make sure extraMessage
  // is always added if it exists
  if (logInfo.extraMessage) {
    trackingPayload = {
      ...trackingPayload,
      extraMessage: logInfo.extraMessage,
    }
  }

  // {...} is a hack to satisfy Typescript, which doesn't like the type
  // otherwise
  if (ahoy) ahoy.track(event, { ...trackingPayload })
  else console.log("Not logging ahoy event")
}

export function logPageView(): void {
  if (dontLogInDevOrTest) return

  const segment = useSegment()
  const userInfoStore = useUserInfoStore()

  // Don't log anonymous pageviews
  if (!userInfoStore.data) return

  if (segment) segment.page()
}
/* eslint-enable import-x/no-named-as-default-member */
